// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-mdx": () => import("./../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-arbour-assembly-how-it-works-arbour-mdx": () => import("./../src/pages/arbour-assembly/how-it-works-arbour.mdx" /* webpackChunkName: "component---src-pages-arbour-assembly-how-it-works-arbour-mdx" */),
  "component---src-pages-arbour-assembly-stand-alone-jsx": () => import("./../src/pages/arbour-assembly/stand-alone.jsx" /* webpackChunkName: "component---src-pages-arbour-assembly-stand-alone-jsx" */),
  "component---src-pages-arbour-assembly-step-1-staking-out-positioning-of-the-posts-mdx": () => import("./../src/pages/arbour-assembly/step-1-staking-out-positioning-of-the-posts.mdx" /* webpackChunkName: "component---src-pages-arbour-assembly-step-1-staking-out-positioning-of-the-posts-mdx" */),
  "component---src-pages-arbour-assembly-step-2-securing-the-posts-to-the-ground-mdx": () => import("./../src/pages/arbour-assembly/step-2-securing-the-posts-to-the-ground.mdx" /* webpackChunkName: "component---src-pages-arbour-assembly-step-2-securing-the-posts-to-the-ground-mdx" */),
  "component---src-pages-arbour-assembly-step-3-attaching-the-main-beams-to-the-posts-mdx": () => import("./../src/pages/arbour-assembly/step-3-attaching-the-main-beams-to-the-posts.mdx" /* webpackChunkName: "component---src-pages-arbour-assembly-step-3-attaching-the-main-beams-to-the-posts-mdx" */),
  "component---src-pages-arbour-assembly-step-4-attaching-the-cross-rafters-mdx": () => import("./../src/pages/arbour-assembly/step-4-attaching-the-cross-rafters.mdx" /* webpackChunkName: "component---src-pages-arbour-assembly-step-4-attaching-the-cross-rafters-mdx" */),
  "component---src-pages-arbour-assembly-step-5-attaching-strengthening-struts-to-main-beams-posts-mdx": () => import("./../src/pages/arbour-assembly/step-5-attaching-strengthening-struts-to-main-beams-posts.mdx" /* webpackChunkName: "component---src-pages-arbour-assembly-step-5-attaching-strengthening-struts-to-main-beams-posts-mdx" */),
  "component---src-pages-arbour-assembly-step-6-finish-mdx": () => import("./../src/pages/arbour-assembly/step-6-finish.mdx" /* webpackChunkName: "component---src-pages-arbour-assembly-step-6-finish-mdx" */),
  "component---src-pages-customer-showcase-jsx": () => import("./../src/pages/customer-showcase.jsx" /* webpackChunkName: "component---src-pages-customer-showcase-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-news-5-ways-to-embrace-the-mediterranean-lifestyle-in-your-back-garden-mdx": () => import("./../src/pages/news/5-ways-to-embrace-the-mediterranean-lifestyle-in-your-back-garden.mdx" /* webpackChunkName: "component---src-pages-news-5-ways-to-embrace-the-mediterranean-lifestyle-in-your-back-garden-mdx" */),
  "component---src-pages-news-how-to-revamp-wooden-outdoor-furniture-mdx": () => import("./../src/pages/news/how-to-revamp-wooden-outdoor-furniture.mdx" /* webpackChunkName: "component---src-pages-news-how-to-revamp-wooden-outdoor-furniture-mdx" */),
  "component---src-pages-news-index-mdx": () => import("./../src/pages/news/index.mdx" /* webpackChunkName: "component---src-pages-news-index-mdx" */),
  "component---src-pages-news-karens-spring-blog-edible-hanging-baskets-mdx": () => import("./../src/pages/news/karens-spring-blog-edible-hanging-baskets.mdx" /* webpackChunkName: "component---src-pages-news-karens-spring-blog-edible-hanging-baskets-mdx" */),
  "component---src-pages-news-pergola-planting-annual-climbers-in-pots-mdx": () => import("./../src/pages/news/pergola-planting-annual-climbers-in-pots.mdx" /* webpackChunkName: "component---src-pages-news-pergola-planting-annual-climbers-in-pots-mdx" */),
  "component---src-pages-news-pergola-planting-climbing-vegetables-mdx": () => import("./../src/pages/news/pergola-planting-climbing-vegetables.mdx" /* webpackChunkName: "component---src-pages-news-pergola-planting-climbing-vegetables-mdx" */),
  "component---src-pages-news-scented-climbers-for-pergolas-mdx": () => import("./../src/pages/news/scented-climbers-for-pergolas.mdx" /* webpackChunkName: "component---src-pages-news-scented-climbers-for-pergolas-mdx" */),
  "component---src-pages-pergola-assembly-assembly-instructions-step-2-mdx": () => import("./../src/pages/pergola-assembly/assembly-instructions-step-2.mdx" /* webpackChunkName: "component---src-pages-pergola-assembly-assembly-instructions-step-2-mdx" */),
  "component---src-pages-pergola-assembly-assembly-instructions-step-3-mdx": () => import("./../src/pages/pergola-assembly/assembly-instructions-step-3.mdx" /* webpackChunkName: "component---src-pages-pergola-assembly-assembly-instructions-step-3-mdx" */),
  "component---src-pages-pergola-assembly-how-it-works-mdx": () => import("./../src/pages/pergola-assembly/how-it-works.mdx" /* webpackChunkName: "component---src-pages-pergola-assembly-how-it-works-mdx" */),
  "component---src-pages-pergola-assembly-staking-out-and-positioning-of-posts-mdx": () => import("./../src/pages/pergola-assembly/staking-out-and-positioning-of-posts.mdx" /* webpackChunkName: "component---src-pages-pergola-assembly-staking-out-and-positioning-of-posts-mdx" */),
  "component---src-pages-pergola-assembly-step-4-attaching-the-main-beams-to-the-posts-mdx": () => import("./../src/pages/pergola-assembly/step-4-attaching-the-main-beams-to-the-posts.mdx" /* webpackChunkName: "component---src-pages-pergola-assembly-step-4-attaching-the-main-beams-to-the-posts-mdx" */),
  "component---src-pages-pergola-assembly-step-5-attaching-the-cross-rafters-mdx": () => import("./../src/pages/pergola-assembly/step-5-attaching-the-cross-rafters.mdx" /* webpackChunkName: "component---src-pages-pergola-assembly-step-5-attaching-the-cross-rafters-mdx" */),
  "component---src-pages-pergola-assembly-step-6-attaching-strengthening-struts-mdx": () => import("./../src/pages/pergola-assembly/step-6-attaching-strengthening-struts.mdx" /* webpackChunkName: "component---src-pages-pergola-assembly-step-6-attaching-strengthening-struts-mdx" */),
  "component---src-pages-pergola-assembly-step-7-finish-mdx": () => import("./../src/pages/pergola-assembly/step-7-finish.mdx" /* webpackChunkName: "component---src-pages-pergola-assembly-step-7-finish-mdx" */),
  "component---src-pages-pergola-planning-delivery-courier-information-mdx": () => import("./../src/pages/pergola-planning/delivery-courier-information.mdx" /* webpackChunkName: "component---src-pages-pergola-planning-delivery-courier-information-mdx" */),
  "component---src-pages-pergola-planning-disclaimer-mdx": () => import("./../src/pages/pergola-planning/disclaimer.mdx" /* webpackChunkName: "component---src-pages-pergola-planning-disclaimer-mdx" */),
  "component---src-pages-pergola-planning-general-information-about-our-pergolas-mdx": () => import("./../src/pages/pergola-planning/general-information-about-our-pergolas.mdx" /* webpackChunkName: "component---src-pages-pergola-planning-general-information-about-our-pergolas-mdx" */),
  "component---src-pages-pergola-planning-hints-to-make-pergola-assembly-easier-mdx": () => import("./../src/pages/pergola-planning/hints-to-make-pergola-assembly-easier.mdx" /* webpackChunkName: "component---src-pages-pergola-planning-hints-to-make-pergola-assembly-easier-mdx" */),
  "component---src-pages-pergola-planning-planning-mdx": () => import("./../src/pages/pergola-planning/planning.mdx" /* webpackChunkName: "component---src-pages-pergola-planning-planning-mdx" */),
  "component---src-pages-pergola-planning-preparation-mdx": () => import("./../src/pages/pergola-planning/preparation.mdx" /* webpackChunkName: "component---src-pages-pergola-planning-preparation-mdx" */),
  "component---src-pages-pergola-planning-quality-statement-mdx": () => import("./../src/pages/pergola-planning/quality-statement.mdx" /* webpackChunkName: "component---src-pages-pergola-planning-quality-statement-mdx" */),
  "component---src-pages-pergola-planning-safety-statement-mdx": () => import("./../src/pages/pergola-planning/safety-statement.mdx" /* webpackChunkName: "component---src-pages-pergola-planning-safety-statement-mdx" */),
  "component---src-pages-pergola-planning-sustainable-forestry-timber-policy-statement-mdx": () => import("./../src/pages/pergola-planning/sustainable-forestry-timber-policy-statement.mdx" /* webpackChunkName: "component---src-pages-pergola-planning-sustainable-forestry-timber-policy-statement-mdx" */),
  "component---src-pages-pergola-planning-tools-and-materials-required-mdx": () => import("./../src/pages/pergola-planning/tools-and-materials-required.mdx" /* webpackChunkName: "component---src-pages-pergola-planning-tools-and-materials-required-mdx" */),
  "component---src-pages-planner-index-jsx": () => import("./../src/pages/planner/index.jsx" /* webpackChunkName: "component---src-pages-planner-index-jsx" */),
  "component---src-pages-planner-success-jsx": () => import("./../src/pages/planner/success.jsx" /* webpackChunkName: "component---src-pages-planner-success-jsx" */),
  "component---src-pages-plants-mdx": () => import("./../src/pages/plants.mdx" /* webpackChunkName: "component---src-pages-plants-mdx" */),
  "component---src-pages-privacy-mdx": () => import("./../src/pages/privacy.mdx" /* webpackChunkName: "component---src-pages-privacy-mdx" */),
  "component---src-pages-shop-3-ds-receive-authorisation-jsx": () => import("./../src/pages/shop/3ds/receive-authorisation.jsx" /* webpackChunkName: "component---src-pages-shop-3-ds-receive-authorisation-jsx" */),
  "component---src-pages-shop-checkout-jsx": () => import("./../src/pages/shop/checkout.jsx" /* webpackChunkName: "component---src-pages-shop-checkout-jsx" */),
  "component---src-pages-shop-customise-jsx": () => import("./../src/pages/shop/customise.jsx" /* webpackChunkName: "component---src-pages-shop-customise-jsx" */),
  "component---src-pages-shop-index-jsx": () => import("./../src/pages/shop/index.jsx" /* webpackChunkName: "component---src-pages-shop-index-jsx" */),
  "component---src-pages-shop-payment-confirmed-jsx": () => import("./../src/pages/shop/payment-confirmed.jsx" /* webpackChunkName: "component---src-pages-shop-payment-confirmed-jsx" */),
  "component---src-pages-shop-payment-failed-jsx": () => import("./../src/pages/shop/payment-failed.jsx" /* webpackChunkName: "component---src-pages-shop-payment-failed-jsx" */),
  "component---src-pages-support-mdx": () => import("./../src/pages/support.mdx" /* webpackChunkName: "component---src-pages-support-mdx" */),
  "component---src-pages-terms-conditions-mdx": () => import("./../src/pages/terms-conditions.mdx" /* webpackChunkName: "component---src-pages-terms-conditions-mdx" */)
}

